import styled from 'styled-components';
import { DropdownButton } from "react-bootstrap";

const Container  = styled.div`
width: 100%;
padding: ${({pagePadding}) => pagePadding ? '0px' : '25px'} ;

.mainContainer{
  width: 100%;
  max-width: 1248px;
  margin: auto;
}
`;

const Heading  = styled.div`
width: 100%;
display: flex;
margin-bottom 15px;
.challenge{
  font-family: "Rubik-Medium";
  font-size: 18px;
  line-height: 21px;
  color:#005c87;
  display: flex;
  margin: auto;
  margin-left:0px;
}
.year-dropdown{
  display: flex;
  margin: auto;
  margin-right: 0px;
}
`;

const ChallangeTypeCard = styled.div`
position:absolute;
top:10px;
left:10px;
font-size: 14px;
font-weight: 400;
line-height: 20px;
background: white;
padding: 5px 10px;
height: 30px;
display: flex;
align-items: center;
justify-content: center;
color: #005c87;
border-radius: 3px;
text-transform: capitalize;
`

const CardWrapper  = styled.div`
width: 100%;
display: flex;
flex-wrap: wrap;
`;

const ChallengeCard  = styled.div`
   width:${({width})=> width ? width : 'calc((100% - 50px) / 3)'};
  height: 100%;
  margin-right:${({margin})=> margin ? margin : '0px'};
  display: flex;
  flex-wrap: wrap;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  margin-bottom: 25px;
  box-shadow: 0px 8px 25px 0px #0D427040;
`;

const ImageCard  = styled.div`
  width: 100%;
  height:${({height})=> height ? height : '254px'};
  cursor: pointer;
  div{
    width: 100%;
  height:${({height})=> height ? height : '254px'};
  cursor: pointer;
    >img{
    width:100%;
    height:100%;
    border-radius: 6px 6px 0 0;
  }

  }
  >img{
    width:100%;
    height:100%;
    border-radius: 6px 6px 0 0;
  }
`;

// const BottomCard  = styled.div`
//   width: 100%;
//   display: flex;
//   border-radius: 0 0 6px 6px;
//   background: white;
//   flex-wrap: wrap;
//   padding: 0px 15px;
//   .challenge-name{
//     width: 100%;
//     display: flex;
//     margin-top: 15px;
//     margin-bottom: 10px;
//     .name{
//         width: calc(100% - 30px);
//         font-family: "Rubik-Medium";
//         font-size: 18px;
//         line-height: 24px;
//         color:#0D4270;
//         display: flex;
//         align-items: center;
//     }
//     .icon{
//         height:24px;
//         width: auto;
//         display: flex;
//         margin: auto;
//         margin-right: 0px;
//         >img{
//             width:25px;
//             height:25px;
//         }
//     }
//   }
// .date{
//   width: 100%;
//   font-family: Rubik;
//   font-size: 16px;
//   line-height: 20px;
//   color:#0D4270;
//   margin-bottom 10px;
// }
// .participant-card{
//   width: 100%;
//   display: flex;
//   margin-bottom 15px;
//   .count{
//       background: linear-gradient(90deg,rgb(0,111,188) -1.11%,rgb(127,67,255) 97.46%);
//       padding: 5px 11px;
//       font-family: Rubik;
//       font-size: 14px;
//       line-height: 20px;
//       color:#fff;
//       border-radius: 16px;
//       height: 30px;
//   }
//   .participant{
//     font-family: Rubik;
//     font-size: 14px;
//     line-height: 20px;
//     color:#9c9c9c;
//     padding-left:5px;
//     display: flex;
//     align-items: center;
//   }
//   .completed{
//       display: flex;
//       font-family: "Rubik";
//       font-size: 16px;
//       line-height: 20px;
//       color:#FD7175;
//       display: flex;
//       margin: auto;
//       margin-right: 0px;
//   }
//   .days{
//     display: flex;
//     font-family: "Rubik";
//     font-size: 14px;
//     line-height: 20px;
//     color: #0D4270;
//     display: flex;
//     margin: auto;
//     margin-right: 0px;
// }
// }
// .button{
//   width: 100%;
//   display: flex;
//   height: 50px;
//   margin-bottom:15px;
//   background: #69C2FF;
//   cursor: pointer;
//   font-family: "Rubik-Medium";
//   font-size: 16px;
//   line-height: 20px;
//   color: #fff;
//   border-radius: 6px;
//   justify-content: center;
//   align-items: center;
// }
// `;
const BottomCard  = styled.div`
  width: 100%;
  display: flex;
  border-radius: 0 0 6px 6px;
  background: white;
  flex-wrap: wrap;
  .challenge-name{
    width: 100%;
    display: flex;
    margin-top: 15px;
    margin-bottom: 10px;
    padding: 0px 15px;
    .name{
        width: calc(100% - 40px);
        font-family: "Rubik-Medium";
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        color:#005c87;
        display: block;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .icon{
        height:24px;
        width: 22px;
        display: flex;
        margin: auto;
        margin-right: 0px;
        /* >img{
            width:25px;
            height:25px;
        } */
    }
  }
.date{
  width: 100%;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color:#005c87;
  margin-bottom:10px;
  padding: 0px 15px;
  display: flex;
}
.participant-card{
  width: 100%;
  display: flex;
  margin: 0px 15px 0px 15px;
  margin-bottom 15px;
  .count{
      background: linear-gradient(90deg,rgb(0,111,188) -1.11%,rgb(127,67,255) 97.46%);
      padding: 5px 11px;
      font-family: Rubik;
      font-size: 14px;
      line-height: 20px;
      color:#fff;
      border-radius: 16px;
      height: 30px;
  }
  .participant{
    font-family: Rubik;
    font-size: 14px;
    line-height: 20px;
    color:#9c9c9c;
    padding-left:5px;
    display: flex;
    align-items: center;
  }
  .days{
    display: flex;
    font-family: "Rubik";
    font-size: 14px;
    line-height: 20px;
    color: #0D4270;
    display: flex;
    margin: auto;
    margin-right: 0px;
}
}
.button{
  width: 100%;
  display: flex;
  height: 50px;
  margin-bottom:15px;
  background: #69C2FF;
  cursor: pointer;
  font-family: "Rubik-Medium";
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
}
`;

const Completed = styled.div`
      display: flex;
      font-family: "Rubik";
      font-size: 14px;
      line-height: 20px;
      color:#0d4270;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      margin:10px 5px 5px 5px;
      height: 40px;
      width: 100%;
      background-color: #f6f6f6;
      border-radius: 6px;
      & > b {
        color : #69c2ff;
        margin:0px 3px
      }
`

const CustomDropDown = styled(DropdownButton)`
  width: 100%;
  display: ${({inputdisplay}) => inputdisplay ? inputdisplay : 'inline-block'};
  color: ${({newWidth}) => newWidth ? 'white' : 'white'};
  background-color: ${({newWidth}) => newWidth ? '#0D4270' : '#ffffff'};
  align-items: center;
  padding: 0px 10px;
  border: 1px solid #cdcbcb;
  text-align: left;
  border: none;
  padding: ${({inputpadding,newWidth}) => inputpadding ? inputpadding : newWidth ? '9px 16px' : '9px 29px'};
  border-radius: 3px;
  color: #9c9c9c;
  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Rubik-Medium';
    float: left;
    max-width:118px;
    white-space:nowrap;
    font-size: 16px;
    line-height: 20px;
    color: #005c87;
    text-transform: capitalize;
  }
  > ul {
    max-width: 300px !important;
  }
  font-size: 14px;
  letter-spacing: 0px;
  color: #282C37;
  font-family: "Rubik-Medium";
  color: #9c9c9c;
  .caret {
    float: right;
    margin-top: 5px;
    display: flex;
    align-self: center;
    color: rgba(105, 194, 255, 1);
    border-width: 9px;
  }
  > li > a {
    text-overflow: ellipses;
    white-space: normal;
    overflow: hidden;
  }
  > span {
    color: #a3a3a3;
    float: right;
    border-top: 6px dashed;
    margin-top: 7px;
  }
  &:active{
    color: #0D4270;
  }
`;

const YearDropDown = styled(CustomDropDown)`
display:flex;
  float: right;
  align-items: center;
  padding: ${({inputpadding}) => inputpadding ? inputpadding : '9px 29px'};
  width: 90px;
  height: 40px;
  background-color: rgba(156, 156, 156, 0.1);
  border-radius: 6px;
  box-shadow: none;
  border:1px solid #9C9C9C;
  margin-top: ${({role}) => (role === 'ADMIN') ? '0px' : '0px'};
  @media (max-width: 760px) and (min-width: 651px) {
     margin-top: ${({role}) => (role === 'ADMIN') ? '-62px' : ''};
  }
  @media (max-width: 650px){
    margin-top: ${({role}) => (role === 'ADMIN') ? '-54px' : '-36px'};
  }
  @media (max-width: 500px){
    margin-left: 0px;
    margin-top: -40px;
  }
  .caret{
    color: #005c87;
  }

  button{
    background:transparent !important;
    border:none;
    color:#005C87;
  }

  button:hover{
    color:#005C87;
  }

  button:focus{
    background-color: transparent !important;
  }
  button:focus-visible{
    background-color: transparent !important;
  }
  button:active{
    background-color: transparent !important;
  }
  .dropdown-menu.show{
    font-family: Rubik-Regular, sans-serif;
    font-size: 14px;
    inset: 3px auto auto 0px !important;
  }
  .dropdown-toggle::after {
    float: right;
    margin-top: -16px;
    display: flex;
    align-self: center;
    color: rgb(0, 92, 135);
    border-width: 9px;
    margin-left: 3.2em;
  }
  .dropdown-item{
    &:last-child{
      cursor: ${({disable}) => disable ? "not-allowed" : 'pointer'};
    }
  }
`;

const NewLabel = styled.label`
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 20px;
text-align: center;
color: #9C9C9C;
display: flex;
margin: auto;
margin-right: 10px;
`;

const NoData = styled.div`
width: 100%;
display: flex;
font-family: "Rubik-Bold";
font-size: 18px;
line-height: 20px;
text-align: center;
color: #0D4270;
`;

export { Container,Completed, Heading, CardWrapper, ChallengeCard, ImageCard, BottomCard, CustomDropDown, YearDropDown, NewLabel, NoData,ChallangeTypeCard };