import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  CardWrapper,
  ChallengeCard,
  ImageCard,
  BottomCard,
  NewLabel,
  YearDropDown,
  NoData,
  TypeContainer,
  Completed,
} from "../DashboardCards/styles";
import _ from "lodash";
import { ImageUrl } from "../../../utils/constants";
import moment from "moment";
import { convertDateInTimezone, convertMonthFromDate } from "../../../utils/methods";
import { DropdownItem } from "react-bootstrap";
import { connect } from "react-redux";
import {
  setSelectedYear,
  getAllCompletedChallenges,
} from "../../../redux/actions";
import { withTranslation } from "react-i18next";
import LazyImage from "../../common/LazyImage/LazyImage";
import { BuddyTab, BuddyTabContainer } from "../../PeopleHomeV2/styles";
import SkeletonLoder from "../../common/skeletonLoder";

class CompletedChallenge extends Component {
  constructor(props){
    super(props);
    this.yearDropdownRef = React.createRef();
  }

  handleMouseup = ()=>{
    // $(".yearDropdownCustomChallenge").css("background-color", "rgba(156, 156, 156, 0.1)");
    this.yearDropdownRef.current.style.backgroundColor = "";
    // console.log("mouse up");
  }
  handleMouseDown = ()=>{
    
    // $(".yearDropdownCustomChallenge").css("background-color", "");
    this.yearDropdownRef.current.style.backgroundColor = "rgba(156, 156, 156, 0.1)";
    // console.log("mouse down");
  }

  componentDidMount() {
    const { getAllCompletedChallenges, selectedYearInfo } = this.props;
    getAllCompletedChallenges(selectedYearInfo || new Date().getFullYear());
  }

  challengeRedirect = (id) => {
    this.props.history.push(`/challenges/${id}`);
  };

  updateDropdown = (year) => {
    const { setSelectedYear, getAllCompletedChallenges } = this.props;
    setSelectedYear(year);
    getAllCompletedChallenges(year);
  };

  ChallengeRedirect = () => {
    this.props.history.push(`/challenges`)
  };

  headerView = (yearList, selectedYearInfo, t) => (
    <BuddyTabContainer marginBottom="25px" style={{marginTop:"25px"}}>
      <BuddyTab width padding active={false} onClick={() => this.ChallengeRedirect()} margin={"10px 0px 10px 15px"} >
        {t("Dashboard")}
      </BuddyTab>
      <BuddyTab
        width
        padding
        active={true}
        // style={{color:'#005c87'}}
        margin={"10px 0px 10px 0px"}
      >
        {" "}
        {t("Completed")}
      </BuddyTab>
      {this.props.isAdmin && <BuddyTab
        width
        padding
        active={false}
        margin={"10px 0px 10px 0px"}
        // style={{color:'#005c87'}}
        onClick={() => this.props.history.push('/challenges/categories')}
      >
        {" "}
        {t("Library")}
      </BuddyTab>}
      <div className="year-dropdown">
        <NewLabel role={"role"} style={{color:'#6399af'}}>{t("Year")}</NewLabel>
        {
          <YearDropDown
            ref={this.yearDropdownRef}
            className="yearDropdownCustomChallenge"
            inputpadding="9px"
            type="button"
            label="year"
            title={
              selectedYearInfo && (
                <span style={{color: "#005C87"}}>{selectedYearInfo}</span>
              )
            }
            onSelect={this.updateDropdown}
            onMouseUp={this.handleMouseup}
            onMouseDown={this.handleMouseDown}
            id="year"
          >
            {yearList.map((list, index) => (   
              <DropdownItem
                key={index}
                eventKey={list}
              >
                {list}
              </DropdownItem>
            ))}
          </YearDropDown>          
        }
      </div>
    </BuddyTabContainer>
  );

  render() {
    const { challengesData, yearList, selectedYearInfo, t } = this.props;
    if (
      !challengesData ||
      _.isNull(challengesData) ||
      _.isUndefined(challengesData)
    ) {
      return <div style={{width:"1246px", margin:'auto'}}>
        <SkeletonLoder width={"100%"} height={"60px"} style={{marginTop:"25px"}}/>
        <div width={"1246px"} height={"354px"} style={{margin:"auto", marginTop:'25px'}}> 
          <div style={{display:'flex', gap:"25px"}}>
            <SkeletonLoder width={"399px"} height={"334px"}/>
            <SkeletonLoder width={"399px"} height={"334px"}/>
            <SkeletonLoder width={"399px"} height={"334px"}/>
          </div>
          <div style={{display:'flex', gap:"25px", marginTop:"25px"}}>
            <SkeletonLoder width={"399px"} height={"334px"}/>
            <SkeletonLoder width={"399px"} height={"334px"}/>
            <SkeletonLoder width={"399px"} height={"334px"}/>
          </div>
          <div style={{display:'flex', gap:"25px", margin:"25px 0px 60px 0px"}}>
            <SkeletonLoder width={"399px"} height={"334px"}/>
            <SkeletonLoder width={"399px"} height={"334px"}/>
            <SkeletonLoder width={"399px"} height={"334px"}/>
          </div>
        </div>
      </div>
    }
   
    return (
      <Container style={{marginBottom:'100px'}}>
        {this.headerView(yearList, selectedYearInfo, t)}
        <div className="mainContainer">
          {/* <Heading> */}
          {/* <div className="challenge">{t("My Completed Challenges")}</div> */}
          {/* </Heading> */}
          {challengesData && challengesData.length > 0 ? (
            <CardWrapper>
              {challengesData &&
                challengesData.length > 0 &&
                challengesData.map((challenge, index) => (
                  <ChallengeCard
                    key={index}
                    margin={(index + 1) % 3 === 0 ? "0px" : "0px"}
                    onClick={() => this.challengeRedirect(challenge.id)}
                  >
                    <TypeContainer>{challenge.challenge_type === 'survivor'?t("Survivor"):
                      challenge.challenge_type === 'group' && challenge.is_team_challenge !== 1 ? t("Classic"):
                        challenge.challenge_type === 'new_group'? t("Group"):
                          challenge.challenge_type === 'group' && challenge.is_team_challenge === 1?t("Team"):t("Behavior")} {t("Challenge")}</TypeContainer>
                    <ImageCard>
                      <img
                        src={`${ImageUrl}/${challenge.image}`}
                        alt="challenge"
                      />
                    </ImageCard>
                    <BottomCard>
                      <div className="challenge-name">
                        <div className="name">
                          {this.props.t(challenge.title)}
                        </div>
                        <LazyImage div={"icon"}
                          src={`${ImageUrl}/${challenge.event_icon}`}
                          alt="event-type"
                        />
                      </div>
                      <div className="date">
                        {convertMonthFromDate(moment(
                          convertDateInTimezone(challenge.start_date)._d
                        ).format("DD MMM, YYYY"), this.props)}
                        {" "}-{" "}
                        {convertMonthFromDate(moment(
                          convertDateInTimezone(challenge.end_date)._d
                        ).format("DD MMM, YYYY"), this.props)}
                      </div>
                      {/* <div className="participant-card">
                        {challenge.user_list &&
                          challenge.user_list.length > 0 && (
                          <div className="count">
                            {challenge.user_list.length}+
                          </div>)}
                        <div className="participant">{t("Participants")}</div>
                      </div> */}
                      <Completed>{t("You earned")} <b>{`+${challenge.user_earn_point} ${t("Social Points")}`}</b> {t("for Participating!")}</Completed>
                    </BottomCard>
                  </ChallengeCard>
                ))}
            </CardWrapper>
          ) : (
            <NoData>{t("No Data Present")}</NoData>
          )}
        </div>
      </Container>
    );
  }
}

CompletedChallenge.propTypes = {
  challengesData: PropTypes.array,
  isAdmin: PropTypes.bool,
  history: PropTypes.object.isRequired,
  yearList: PropTypes.array,
  selectedYearInfo: PropTypes.number,
  setSelectedYear: PropTypes.func,
  getAllCompletedChallenges: PropTypes.func,
  t: PropTypes.func,
};

const mapStateToProps = (state) => ({
  activeTab: state.challenges.activeTab,
  isLoading: state.challenges.isLoading,
  challengesData: state.challenges.response,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedYear: (year) => dispatch(setSelectedYear(year)),
  getAllCompletedChallenges: (year) =>dispatch(getAllCompletedChallenges(year)),
});

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(CompletedChallenge));
